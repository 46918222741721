<template>
  <div class="patient page">
    <NavMenu>
      <div class="right-warp">
        <div class="right-content">
          <h2 class="content-title">
            <div class="title">
              <img src="../../assets/img/icon_5.png" alt="ico-title" />
              <span>{{ $t("account.log") }}</span>
            </div>
          </h2>
          <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index">
              <div class="info">
                <img src="../../assets/img/windows.png" alt="" />
                <div>
                  <p class="system">{{ item.os }}</p>
                  <p>{{ item.address }} {{ item.ip }}</p>
                </div>
              </div>
              <div class="login-time">
                {{ item.add_time }}
              </div>
            </div>
            <div class="list-foot">
              <el-pagination
                @current-change="nowpage"
                background
                layout="prev, pager, next"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </NavMenu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      total: 0,
      params: {
        page: 1,
        limit: 10,
      },
      ruleForm: {},
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 当前页
    nowpage(e) {
      this.params.page = e;
      this.getList();
    },
    //获取Log列表
    getList() {
      this.$api.userLoginLog(this.params).then((res) => {
        this.list = res.data.data;
        this.total = res.data.count;
      });
    },
  },
};
</script>
<style scoped>
.right-content {
  /* min-height: 757px; */
  position: relative;
}
.content-title .mark {
  font-family: FZZhunYuan-M02S;
  font-weight: 400;
  line-height: 159px;
  font-size: 13px;
}
.sig {
  color: #ff0000;
}
.right-content .form {
  display: flex;
  justify-content: space-between;
  padding: 30px;
}
.right-content .form-left,
.right-content .form-right {
  width: 45%;
}
.right-content .form .foot {
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  text-align: center;
}
.right-content .form .foot input {
  margin: 0 12px;
}
.btn {
  background: #fff;
}
.list {
  padding: 20px 12px;
}
.list .item {
  padding: 15px 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #686868;
  background: #f1f5f7;
  margin-bottom: 10px;
}
.list .item .info {
  display: flex;
  align-items: center;
}
.list .item .info img {
  width: 54px;
  height: 54px;
  margin-right: 15px;
}
.list .item .system {
  font-size: 21px;
  font-weight: bold;
  color: #1f2a66;
  margin-bottom: 8px;
}
.list-foot {
  text-align: right;
}
</style>
